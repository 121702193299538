<template>
  <v-content>
    <dashboard-core-app-bar/>

    <router-view />

    <dashboard-core-footer />
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      DashboardCoreFooter: () => import('./Footer'),
      DashboardCoreAppBar: () => import('./AppBar'),
    },
  }
</script>
